<template>
  <div class="home-container">
    <FeaturePanel id="panel" :image="'feature-order-confirmed.svg'" />
    <div class="__action-panel">
      <div class="__success-prompt">
        <img src="../assets/images/icons/tick.svg" />
        <p>Thanks for verifiying your order with <a href="https://spotriskhq.com">Spotrisk</a></p>
      </div>
      <h1>Order Confirmation <span class="__heading--success">Successful!</span></h1>
      <p class="__result--summary">Your Order: <a>{{state.order.name}}</a> from <a>{{state.merchant.name}}</a> has been approved and is on it’s way.</p>
      <p class="__result--sub-text">
        <strong>Did you know that 12% of all online e-commerce store are fake?</strong>
      </p>
      <p class="__result--sub-text">
        Spotrisk is leader in online safety for both e-commerce stores and their customers. 
        Click Find out more and protect yourself today!
      </p>
      <BottomActions :label="'Find out more'" :url="'https://spotriskhq.com'"/>
      <img class="__detail" src="../assets/images/details/success-detail.svg">
    </div>
  </div>
</template>

<script>
import FeaturePanel from './FeaturePanel.vue';
import BottomActions from './BottomActions.vue';
import { useRootStore } from '../stores/root.js';
import { onMounted, reactive } from 'vue';

export default {
  name: 'OrderConfirmationSuccess',
  components: {
    FeaturePanel,
    BottomActions
  },
  setup() {
    const state = reactive({
      order: {},
      merchant: {}
    });

    onMounted(async () => {
      const rootStore = await useRootStore();
      
      const orderData = rootStore?.orderStore?.getOrderData;
      state.order = orderData?.order;
      state.merchant = orderData?.merchant;
    });

    return {
      state
    }
  }
}
</script>

<style>
.__success-prompt {
  padding: 14px;
  border-radius: 50px;
  margin-bottom: 10px;
  background: #E5F7EF;
  display: flex;
  align-items: center;
}

.__action-panel .__success-prompt p {
  color: #00B05C;
  font-size: 14px;
  margin: 0;
  line-height: auto;
}

.__success-prompt img {
  margin-right: 7px;
}

.__success-prompt a {
  color: #0C8AFF
}
</style>

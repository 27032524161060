<template>
    <div class="__bottom-actions">
        <button @click="primaryAction">{{ props.label }}</button>
        <a target="_blank" href="https://spotriskhq.com/contact">Need help?</a>
    </div>
</template>

<script>
import { useStepsStore } from '@/stores/steps.js';
import { useVerifyStore } from  '@/stores/verify.js';

export default {
  name: 'BottomActions',
  props: {
    label: {
      type: String,
      required: true,
      default: 'Continue'
    },
    url: {
      type: String,
      required: false
    },
    validated: {
      type: Boolean,
      default: true,
    },
    refresh: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props, {emit}) {
    const store = useStepsStore();
    const verifyStore = useVerifyStore();

    async function primaryAction() {
      const stepsStore = useStepsStore();
      emit('primaryAction');

      if (!props.validated) {
        return;
      }

      if (props.url) {
        window.location.assign(props.url);
      } else if (props.refresh) {
        verifyStore.checkStatus();
      } else {
        stepsStore.nextStep();
      }
    }

    return {
        store,
        props,
        primaryAction
    }
  }
}
</script>

<style>
  .__bottom-actions {
    margin-top: 30px;
  }

 .__bottom-actions button {
  margin-bottom: 20px;
  width: 220px;
 }

 @media only screen and (max-width: 980px) {
  .__bottom-actions {
    margin-top: auto;
  }
  .__bottom-actions button {
    margin-bottom: 20px;
    width: 100%;
  }
 }
</style>

<template>
  <div class="home-container">
    <FeaturePanel id="panel" :image="'feature-order-unsuccessful.svg'" />
    <div class="__action-panel">
      <div class="__failure-prompt">
        <img src="../assets/images/icons/alert.svg" />
        <p>Your order ({{state.order?.name}}) from {{state.merchant?.name}} has been refunded.</p>
      </div>
      <h1>Order Confirmation <br> <span class="__heading--failure">Unsuccessful!</span></h1>
      <p class="__result--summary">Please contact <a :href="`mailto=${state.merchant?.email}?subject=Order ${state.order.name} verification`">{{state.merchant?.email}}</a> for further assistance.</p>

      <BottomActions :label="'Contact Merchant'" :url="`mailto:${state.merchant?.email}?subject=Order ${state.order.name} verification`"/>
      <img class="__detail" src="../assets/images/details/failure-detail.svg">
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue';
import FeaturePanel from './FeaturePanel.vue';
import BottomActions from './BottomActions.vue';
import { useRootStore } from '../stores/root.js';

export default {
  name: 'OrderConfirmationFailure',
  components: {
    FeaturePanel,
    BottomActions
  },
  setup() {
    const state = reactive({
      order: {},
      merchant: {}
    });
    const rootStore = useRootStore();
    onMounted(async () => {
      await rootStore.load();

      const orderData = rootStore?.orderStore?.getOrderData;
      state.order = orderData?.order;
      state.merchant = orderData?.merchant;
    });

    return {
      state
    }
  }
}
</script>

<style>
.__failure-prompt {
  background: #FFF4F3;
  padding: 14px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.__action-panel .__failure-prompt p {
  color: #FF2A1D;
  font-size: 14px;
  margin: 0;
  line-height: 22px;
}
</style>

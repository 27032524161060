import axios from 'axios';
// import { isDev } from '@/lib/util/isDev.js';
// import { getVerifyMock } from '@/lib/request/mock/verifyMock';
import { useRootStore } from '@/stores/root.js';

// function timeout(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

export async function postVerify (payload) {
    const rootStore = useRootStore();
    const endpoint = '/2020-06/ecommerce/customer_verification/verify?token=';

    // if (isDev) {
    //     await timeout(1500)
    //     // Mock with 'failed', 'pending' or 'success'
    //     // return getVerifyMock('failed');
    //     // return getVerifyMock('pending');
    //     return getVerifyMock('success');
    // }

    const response = await axios.post(
        rootStore.getRequestBaseUrl + endpoint + rootStore.getToken, 
        payload
    );

    return response.data;
}

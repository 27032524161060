<template>
  <div class="__error-container">
    <div class="__error-content">
        <img :src="LoadingAsset" alt="Spotrisk Logo" />
        <h1>Error</h1>
        <p>This is the Spotrisk verification app. It looks like you've arrived here without using the correct link. If you believe this is a mistake, please contact us at <a href="mailto:support@spotriskhq.com">support@spotriskhq.com</a>.</p>
        <p>Otherwise, <a href="https://www.spotriskhq.com">click here</a> to go to www.spotriskhq.com.</p>
    </div>    
  </div>
</template>

<script>
import LoadingAsset from '../assets/images/spot-head.png';
export default {
  name: 'ErrorPageNoToken',
  setup() {
    return {
        LoadingAsset
    }
  }
}
</script>

<style scoped>
.__error-container {
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background: #fff;
    text-align: center;
}

.__error-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
}
h1 {
    margin-bottom: 10px;
}
img {
    width: 100px;
    display: block;
    margin: 0 auto 30px;
 }
a {
    color: #007BFF; /* You can adjust the color as needed */
    text-decoration: underline;
}
</style>

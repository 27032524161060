import { defineStore } from 'pinia';
import { useVerifyStore } from '../stores/verify.js';
import { useOrderStore } from '@/stores/order.js';
import * as Sentry from "@sentry/vue";

export const useStepsStore = defineStore('step', {
    state: () => ({ 
      step: 0,
      status: {
        isLoading: false,
        verification: 'PENDING',
        error: false
      }
    }),
    getters: {
      getStep: (state) => {
        return state.step
      },
      isLoading: (state) => {
        return state.status.isLoading
      },
      isSuccess: (state) => {
        return state.status.verification === 'SUCCESS'
      },
      isPending: (state) => {
        return state.status.verification === 'PENDING'
      },
      isEscalated: (state) => {
        return state.status.verification === 'ESCALATED'
      },
      isFailure: (state) => {
        return state.status.verification === 'FAILED'
      }
    },
    actions: {
      async setInitalStep() {
        const orderStore = await useOrderStore();
        if (orderStore.data.status) {
          this.status.verification = orderStore.data.status;
        }

        if (
          this.isSuccess || 
          this.isEscalated ||
          this.isFailure) {
          this.step = 2;
        }
      },
      async nextStep() {
        const verifyStore = useVerifyStore();

        if (this.step === 1) {
          // Call verify
          this.status.isLoading = true;
          this.status.error = false;
          try {
            const response = await verifyStore.verify();
            this.status.verification = response.status;          
            
            setTimeout(() => {this.status.isLoading = false}, 5000);
          } catch(e) {
            this.status.isLoading = false;
            this.status.error = true;
            console.error(e);
            Sentry.captureException(e);
            return;
          }
        }
        this.step++;
      },

    },
  });
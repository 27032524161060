import { defineStore } from "pinia";
import { useOrderStore } from "./order.js";
import { useStepsStore } from "./steps.js";
import { useVerifyStore } from "../stores/verify.js";
import { isDev } from "@/lib/util/isDev.js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const useRootStore = defineStore("root", {
  state: () => ({
    fingerPrint: "",
    token: "",
    orderStore: useOrderStore(),
    stepsStore: useStepsStore(),
    verifyStore: useVerifyStore(),
    errors: {
      loadingError: false,
      tokenNotFound: false,
    },
  }),
  getters: {
    isLoading: (state) => {
      return () =>
        state.orderStore.status.isLoading ||
        state.verifyStore.status.isLoading ||
        state.stepsStore.status.isLoading;
    },
    isError: (state) => {
      return () => state.errors.loadingError;
    },
    isTokenNotFound(state) {
      return () => state.errors.tokenNotFound;
    },

    getToken: (state) => {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      let value = params.token;

      if (value) {
        state.token = value;
      } else {
        console.log("No token found in URL");
        state.errors.tokenNotFound = true;
      }

      return state.token;
    },
    getDisableAddressAutoComplete: () => {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      return params.disable_address_autocomplete === "true";
    },
    getRequestBaseUrl: () => {
      let baseUrl = "https://api.spotriskhq.com";
      if (isDev()) {
        baseUrl = "https://api.spotriskhq.dev.local";
      }
      return baseUrl;
    },
  },
  actions: {
    async load() {
      await this.orderStore.load();
      const fingerPrintRequest = await FingerprintJS.load();
      const fingerPrint = await fingerPrintRequest.get();
      this.fingerPrint = fingerPrint.visitorId;
    },
  },
});

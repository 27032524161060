<template>
  <div class="home-container">
    <FeaturePanel id="panel" :image="'feature-confirm-details.svg'" :note="'Please complete this process within 48 hours to avoid your order being refunded.'" />
    <div class="__action-panel">
      <div class="__action-panel--content">
        <h1>Confirm details</h1>
          <p>Please confrim some quick details to complete your purchase <br>
            <span>
              Order: 
              <a v-if="state.order?.name">{{state?.order?.name}}</a><br>
              <span v-if="state.merchant?.name"> from </span>
              <a v-if="state.merchant?.name">{{state?.merchant?.name}}</a>
            </span>
          </p>
          <div class="__notice">
            <img src="../assets/images/icons/clock.svg" alt="" />
            <p>This will only take 30 seconds.</p>
          </div>

          <div class="__note">
            <strong>Note:</strong>
            <p>
              Please complete this process within 48 hours to avoid
              your order being refunded.
            </p>
          </div>
        </div>
      <BottomActions :label="'Let\'s go'"/>
    </div>
    <img class="__detail" src="../assets/images/details/confirm-details.svg">
  </div>
</template>

<script>
import FeaturePanel from '../components/FeaturePanel.vue';
import BottomActions from './BottomActions.vue';
import { useRootStore } from '../stores/root.js';
import { onMounted, reactive } from 'vue';

export default {
  name: 'ConfirmDetails',
  components: {
    FeaturePanel,
    BottomActions
  },
  setup() {
    const state = reactive({
      order: {},
      merchant: {}
    });
    onMounted(async () => {
      const rootStore = useRootStore();
      await rootStore.load();
      
      const orderData = rootStore?.orderStore?.getOrderData;
      state.order = orderData.order;
      state.merchant = orderData.merchant;
    });

    return {
      state
    }
  }
}
</script>

<style>
  .__notice {
    display: flex;
    align-items: center;
    margin: 0 auto 35px;
    gap: 10px;
  }
  .__action-panel .__notice p {
    font-size: 16px;
    margin: 0;
    color: #343434;
  }

  .__action-panel .__note p,
  .__action-panel .__note strong {
    font-size: 14px;
    line-height: 22px;
    color: #646464;    
  }

  .__detail {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  @media only screen and (max-width: 980px) {
    .__detail {
      display: none;
    }
    .__notice {
      justify-content: center;
    }
    .__action-panel .__note {
      text-align: center;
    }
  }
</style>

<template>
  <div class="home-container">
    <FeaturePanel id="panel" :image="'feature-verify-info.svg'" />
    <div class="__action-panel">
        <h1>Verify your order information</h1>
            <p>Please confrim the following details about your order.
            <a v-if="state.order?.name">
              Order: ({{state.order.name}} {{state.merchant.name}})
            </a>
        </p>

        <form class="__verifiy-form">
          <div class="--form-row">
            <label>Phone Number</label>
            <input v-model="state.phone" ref="phoneRef" type="tel" @blur="formatPhone">
          </div>
          
          <div class="--form-row">
            <label>Shipping Address</label>
              <place-autocomplete-field 
                v-model="state.address"
                v-if="isAutoCompleteEnabled()"
                placeholder="Address line"
                name="address"
                api-key="AIzaSyDcqj195Xg3qayPVgMp4eILGLXeC_EkYq4"
                @select="setAddress"
                ></place-autocomplete-field>
              <input v-else v-model="verifyStore.payload.shipping_address.address_1" type="text" placeholder="Adress Line" name="address">
              <input v-model="verifyStore.payload.shipping_address.city" type="text" placeholder="City" name="city">
              <input v-model="verifyStore.payload.shipping_address.state" type="text" class="__half-input" placeholder="State" name="state">
              <input v-model="verifyStore.payload.shipping_address.postal_code" class="__half-input" type="text" placeholder="Postcode" name="postcode">
              <input v-model="verifyStore.payload.shipping_address.country" type="text" placeholder="Country" name="country">
          </div>

          <div class="--form-row">
            <label>Order Amount</label>
            <input
              v-model="verifyStore.payload.order_amount"
              type="text"
              name="order-amount"
              placeholder="Type your amount">
          </div>
          <span v-if="state.formError !== '' && !state.valid" class="error">{{ state.formError }}</span>
          <span v-if="stepsStore.status.error" class="error">Something went wrong, please check you entered all required fields</span>
        </form>        
        <BottomActions :label="'Continue'" :validated="state.valid" @primaryAction='validateForm'/>
        <img class="__detail" src="../assets/images/details/verify-detail.svg">
  </div>
  </div>
</template>

<script>
import FeaturePanel from '../components/FeaturePanel.vue';
import BottomActions from './BottomActions.vue';
import { useRootStore } from '@/stores/root.js';
import { useVerifyStore } from '@/stores/verify.js';
import { useStepsStore } from '@/stores/steps.js';
import { onMounted, reactive, ref } from 'vue';
import intlTelInput from 'intl-tel-input';
export default {
  name: 'VerifyInformation',
  components: {
    FeaturePanel,
    BottomActions
  },
  setup() {
    const state = reactive({
      order: {},
      merchant: {},
      phone: '',
      address: '',
      valid: false,
      formError: '',
    });

    const verifyStore = useVerifyStore();
    const stepsStore = useStepsStore();

    let phoneInput;
    const phoneRef = ref(null);

    onMounted(async () => {
      const rootStore = await useRootStore();
      
      const orderData = rootStore.orderStore.getOrderData;
      state.order = orderData.order;
      state.merchant = orderData.merchant;

      const input = getPhoneInput();
      phoneInput = intlTelInput(input, {
        separateDialCode: true,
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js'
      });
    });

    function isAutoCompleteEnabled() {
      const rootStore = useRootStore();
      const result = rootStore.getDisableAddressAutoComplete;

      return !result;
    }

    function getPhoneInput() {
      return phoneRef.value;
    }

    function setAddress(place, response) {
      response.address_components.map(component => {
        if (component.types.includes('street_number') && component.long_name) {
          verifyStore.payload.shipping_address.address_1 = component.long_name + ' ';
          state.address = verifyStore.payload.shipping_address.address_1
        } else if (component.types.includes('route') && component.long_name) {
          verifyStore.payload.shipping_address.address_1 += component.long_name;
          state.address = verifyStore.payload.shipping_address.address_1
        } else if (component.types.includes('locality') && component.long_name) {
          verifyStore.payload.shipping_address.city = component.long_name;
        } else if (component.types.includes('administrative_area_level_1') && component.long_name) {
          verifyStore.payload.shipping_address.state = component.long_name;
        } else if (component.types.includes('country') && component.long_name) {
          verifyStore.payload.shipping_address.country = component.long_name;
        } else if (component.types.includes('postal_code') && component.long_name) {
          verifyStore.payload.shipping_address.postal_code = component.long_name;
        }
      });
    }

    function formatPhone() {
      const phone = getPhoneInput().value.replace(/[{()}a-zA-z ]/g, '');
      phoneInput.setNumber(phone);
      verifyStore.payload.phone_number = phoneInput.getNumber()
    }

    function validateForm() {
      const { payload } = verifyStore;
      const { shipping_address } = payload;

      if (!shipping_address.address_1 || shipping_address.address_1 === '') {
        shipping_address.address_1 = state.address;
      }

      if (payload.phone_number === '') {
        updateFormError('Phone number field is empty, all fields are required');
        return;
      }

      if (payload.order_amount === '') {
        updateFormError('Order amount is empty, all fields are required');
        return;
      }

      if (Object.keys(shipping_address).length === 0 || shipping_address.address_1 === "") {
        updateFormError('Some fields were left empty, all fields are required');
        return;
      }
      stepsStore.nextStep();
      state.valid = true;
      state.formError = '';  
    }

    function updateFormError(errorMessage) {
      state.valid = false;
      state.formError = errorMessage;
    }

    return {
      state,
      verifyStore,
      isAutoCompleteEnabled,
      stepsStore,
      phoneRef,
      setAddress,
      validateForm,
      formatPhone,
    }
  }
}
</script>

<style>
@import '~intl-tel-input/build/css/intlTelInput.css';

.autocomplete-field {
  width: 100%
}

.__verifiy-form input {
  background: #F1F5F5;
  border-radius: 5px;
  border: 1px solid #fff;
}

.iti--allow-dropdown{
  width: 100%;
}

.__verifiy-form input:focus {
  color: #144AB6;
  background: #F1F6FB;
  border: 1px solid #144AB6;
}

label {
  text-align: left;
  color: #646464;
  display: block;
  margin-bottom: 5px;
}

.--form-row {
  margin-bottom: 25px;
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
}

.__half-input {
  width: 49%;
  -webkit-appearance: none;
}

.__half-input::-webkit-outer-spin-button,
.__half-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.--form-row input {
  margin-bottom: 8px;
}

.vti__dropdown {
  background: #F1F5F5;
}

.__verifiy-form .vti__selection .vti__country-code,
.__verifiy-form .vti__selection .vti__dropdown-arrow {
  color: #144AB6;
}

.vti__selection::after{
  height: 85%;
  width: 1px;
  background: #646464;
  display: block;
}

.__verifiy-form .vti__selection .vti__country-code {
  background: #F1F5F5;
  margin-right: 10px;
  font-size: 18px;
}

.autocomplete-list-wrapper {
  width: 100%;
  position: relative;
}

.autocomplete-list-wrapper ul.autocomplete-list{
  list-style: none;
  position: absolute;
  padding: 10px;
  border: 2px solid #F1F5F5;
  background: #fff;
}

.autocomplete-list-item{
  margin-bottom: 15px;
  text-align: left;
  padding-left: 19px;
}

.autocomplete-list-item a {
  color: #144AB6;
}

.autocomplete-list-item::before{
  content:url('../assets/images/icons/map-marker.png');
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
}

.__verifiy-form .error {
  background: #FFF4F3;
  padding: 14px;
  border-radius: 5px;
  display: block;
  color: #FF2A1D;
  font-size: 18px;
  margin-bottom: 20px;
}
</style>

<template>
  <div class="__error-container">
    <div class="__error-content">
        <img src="../assets/images/spot-head.png" />
        <h1>Error</h1>
        <p>Something went wrong, please check your link or try again</p>
    </div>    
  </div>
</template>

<script>
import LoadingAsset from '../assets/images/spot-head.png';
export default {
  name: 'ErrorPage',
  setup(props) {
    return {
        props,
        LoadingAsset
    }
  }
}
</script>

<style scoped>
.__error-container {
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background: #fff;
    text-align: center;
}

.__error-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
}
h1 {
    margin-bottom: 10px;
}
img {
    width: 100px;
    display: block;
    margin: 0 auto 30px;
 }
</style>

<template>
  <div class="__feature-panel">
    <img class="__logo" src="../assets/images/spotrisk-logo-full-white.svg">
    <img class="__feature-detail" src="../assets/images/details/feature-detail.svg">
    <img class="__feature-image" :src="getImgUrl()" alt="" />
    <p v-if="props.note" class="__note">Note: <br> {{ props.note }}</p>
  </div>
</template>

<script>

export default {
  name: 'FeaturePanel',
  props: {
    image: {
      type: String,
      required: true,
      default: 'feature-confirm-details.svg'
    },
    note: {
      type: String
    }
  },
  setup(props) {
    function getImgUrl() {
      const images = require.context('../assets/images', false, /\.svg/);
      return images('./' + props.image);
    }

    return {
      props,
      getImgUrl,
    }
  }
}

</script>

<style>
.__feature-panel {
    background: #144AB6;
    color: #fff;
    position: relative;
}

.__feature-panel .__logo {
  position: absolute;
  left: 40px;
  top: 40px;
  width: 131px;
}

.__feature-panel .__feature-detail {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 61px;
}

.__feature-panel .__feature-image {
    width: 550px;
    max-width: 90%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.__feature-panel .__note {
  color: #D6D6D6;
  bottom: 40px;
  left: 40px;
  position: absolute;
  max-width: 90%;
  font-size: 16px;
  line-height: 22px;
}

@media only screen and (max-width: 980px) {
  .__feature-panel .__logo,
  .__feature-panel .__detail,
  .__feature-panel .__note,
  .__feature-panel .__feature-detail {
    display: none;
  }

  .__feature-panel .__feature-image {
    max-height: 100%;
    height: 220px;
    width: auto;
    top: 55%;
  }
}
</style>

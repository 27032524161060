import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import App from './App.vue'
import VuePlaceAutocomplete from 'vue-place-autocomplete';

const pinia = createPinia()
const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://4805f9876e9441d0a0a9125761716acc@o4504261822119936.ingest.sentry.io/4504261832343553",
    integrations: [
        new BrowserTracing({
        tracePropagationTargets: ["localhost", "verification.spotriskhq.com", /^\//],
        }),
    ],
    tracesSampleRate: 1.0,
});

app.mixin(Sentry.createTracingMixins({ trackComponents: true }));
Sentry.attachErrorHandler(app);

app.use(pinia)
app.use(VuePlaceAutocomplete);
app.mount('#app')
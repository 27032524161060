// import { getVerifyMock } from '@/lib/request/mock/verifyMock';
// import { getStatusMock } from '@/lib/request/mock/statusMock';
import { postVerify } from '@/lib/request/postVerify';
import { fetchStatus } from '@/lib/request/fetchStatus';
import { defineStore } from 'pinia';
import { useRootStore } from './root';
import { useStepsStore } from './steps.js';
import * as Sentry from "@sentry/vue";

export const useVerifyStore = defineStore('verify', {
    state: () => ({ 
      data: [],
      payload: {
        finger_print_id: '',
        phone_number: '',
        shipping_address: {},
        order_amount: ''
      },
      status: {
        isLoading: false
      },
      errors: {
        loadingError: null
      }
    }),
    getters: {
        getVerifyPayload: (state) => {
          return state.payload;
        },
    },
    actions: {
        formatPayload() {
          const rootStore = useRootStore();

          //Ensure postcode is string
          const postCode = this.payload?.shipping_address?.postal_code
          if (postCode) {
            this.payload.shipping_address.postal_code = postCode.toString();
          }
          
          // Turn order amount into float
          let orderAmount = this.payload.order_amount.toString();
          orderAmount = parseFloat(orderAmount.replace(/[^a-zA-Z0-9. ]/g, ''));
          this.payload.order_amount = orderAmount;
          
          // Assing Fingerprint to payload
          this.payload.finger_print_id = rootStore.fingerPrint;
        },
        async verify() {
            // start loading
            this.status.isLoading = true;
            try {
                // post verify request
                this.formatPayload();
                // this.data = getVerifyMock('pending');
                this.data = await postVerify(this.payload);
                this.status.isLoading = false;
                return this.data;
            } catch (e) {
                this.errors.loadingError = e;
                this.status.isLoading = false;
                console.error(e);
                Sentry.captureException(e);
            } finally {
                // stop loading
                this.status.isLoading = false;
            }
        },
        async checkStatus() {
          const stepStore = useStepsStore();
          this.status.isLoading = true;
          try {
            const response = await fetchStatus();
            // const response = getStatusMock('success');
            stepStore.status.verification = response.status;
            this.status.isLoading = false;
            return response;
          } catch (e) {
              this.errors.loadingError = e;
              this.status.isLoading = false;
              console.error(e);
              Sentry.captureException(e);
          } finally {
              // stop loading
              this.status.isLoading = false;
          }
        }
    },
  })
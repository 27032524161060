import axios from 'axios';
import { useRootStore } from '@/stores/root.js';

export async function fetchStatus () {
    const rootStore = useRootStore();
    const endpoint = '/2020-06/ecommerce/customer_verification/status?token=';

    const response = await axios.get(rootStore.getRequestBaseUrl + endpoint + rootStore.getToken);
    
    return response.data;
}
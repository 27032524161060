<template>
  <div class="home-container">
    <FeaturePanel id="panel" :image="'feature-order-pending.svg'" />
    <div class="__action-panel">
      <h1>Order Confirmation <br> <span class="__heading--pending">Pending</span></h1>
      <p>
        Your purchase {{getMerchantName()}} is being verified. This should only take a few minutes. We will
        email you upon completion of the verification process or click refresh below.
      </p>

      <BottomActions :label="'Refresh'" :refresh="true"/>
      <img class="__detail" src="../assets/images/details/pending-detail.svg">
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue';
import FeaturePanel from './FeaturePanel.vue';
import BottomActions from './BottomActions.vue';
import { useRootStore } from '../stores/root.js';
import { useVerifyStore } from '../stores/verify.js';

export default {
  name: 'OrderConfirmationPending',
  components: {
    FeaturePanel,
    BottomActions
  },
  setup() {
   const state = reactive({
      order: {},
      merchant: {}
    });
    const rootStore = useRootStore();
    const verifyStore = useVerifyStore();

    async function retryVerification() {
      try {
        await verifyStore.checkStatus();
      } catch (e) {
        console.error('Verify Error: ', e);
      }
    }
    function getMerchantName() {
      if (state.merchant?.name) {
        return "with " +  state.merchant?.name;
      }
    }

    onMounted(async () => {
      await rootStore.load();

      const orderData = rootStore?.orderStore?.getOrderData;
      state.order = orderData?.order;
      state.merchant = orderData?.merchant;

      setTimeout(async () => { retryVerification() }, 6000);
    });

    return {
      state,
      getMerchantName
    }
  }
}
</script>

<style>

</style>

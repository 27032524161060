import axios from 'axios';
// import { isDev } from '@/lib/util/isDev.js';
// import { getBeginMock } from '@/lib/request/mock/beginMock';
import { useRootStore } from '@/stores/root.js';

export async function fetchBegin () {
    const rootStore = useRootStore();
    const endpoint = '/2020-06/ecommerce/customer_verification/begin?token=';

    // if (isDev) {
    //     return getBeginMock();
    // }

    const response = await axios.get(rootStore.getRequestBaseUrl + endpoint + rootStore.getToken);
    return response.data;
}
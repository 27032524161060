<template>
  <div>
    <LoadingScreen v-if="rootStore.isLoading() && !rootStore.isError()"/>
    <ErrorPage v-if="!rootStore.isLoading() && rootStore.isError()"/>
    <ErrorPageNoToken v-if="!rootStore.isLoading() && rootStore.isTokenNotFound()"/>
    <ConfirmDetails v-if="stepsStore.getStep === 0 && !rootStore.isError()" />
    <VerifyInformation v-if="stepsStore.getStep === 1 && !rootStore.isError()"/>
    <OrderConfirmationSuccess v-if="stepsStore.getStep === 2 && stepsStore.isSuccess && !rootStore.isError()"/>
    <OrderConfirmationPending v-if="stepsStore.getStep === 2 && stepsStore.isEscalated && !rootStore.isError()"/>
    <OrderConfirmationFailure v-if="stepsStore.getStep === 2 && stepsStore.isFailure && !rootStore.isError()"/>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import ConfirmDetails from '../components/ConfirmDetails.vue';
import VerifyInformation from '../components/VerifyInformation.vue';
import LoadingScreen from '../components/LoadingScreen.vue';
import ErrorPage from '../components/ErrorPage.vue';
import ErrorPageNoToken from '@/components/ErrorPageNoToken.vue';
import { useStepsStore } from '../stores/steps.js';
import { useRootStore } from '../stores/root.js';
import OrderConfirmationSuccess from '../components/OrderConfirmationSuccess.vue';
import OrderConfirmationPending from '../components/OrderConfirmationPending.vue';
import OrderConfirmationFailure from '../components/OrderConfirmationFailure.vue';

export default {
  name: 'HomeView',
  components: {
    ConfirmDetails,
    VerifyInformation,
    LoadingScreen,
    ErrorPage,
    ErrorPageNoToken,
    OrderConfirmationSuccess,
    OrderConfirmationPending,
    OrderConfirmationFailure
  },
  setup() {
    const rootStore = useRootStore();
    const stepsStore = useStepsStore();
    
    onMounted(async () => {
      await rootStore.load();
      stepsStore.setInitalStep();
    });

    return {
      rootStore,
      stepsStore
    }
  }
}
</script>

<style>
.home-container {
  display: flex;
  min-height: 100vh;
}

#panel {
    width: 50vw;
    height: 100vh;
    display: block;
}

.__action-panel {
  padding: 25px;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 610px;
  margin: 0 auto;
}

@media only screen and (max-width: 980px) {
  .home-container {
    flex-direction: column;
  }

  #panel {
    width: 100%;
    height: 300px;
  }

  .__action-panel {
    text-align: center;
    padding: 20px;
  }
}

</style>

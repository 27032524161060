// import beginMock from '@/lib/request/mock/beginMock';
import { fetchBegin } from '@/lib/request/fetchBegin';
import { defineStore } from 'pinia';

export const useOrderStore = defineStore('order', {
    state: () => ({ 
      data: [],
      status: {
        isLoading: false
      },
      errors: {
        loadingError: null
      }
    }),
    getters: {
      getOrderData: (state) => {
        return state.data
      },
      getOrderStatus: (state) => {
        return state.data.status
      }
    },
    actions: {
      async load() {
        // start loading
        this.status.isLoading = true;
        try {
          // this.data = beginMock;
          this.data = await fetchBegin();
          this.status.isLoading = false;
        } catch (e) {
          this.errors.loadingError = e;
          console.error(e.message);
          this.status.isLoading = false;
        } finally {
          // stop loading
          this.status.isLoading = false;
        }
      }
    },
  })
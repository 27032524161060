<template>
  <div class="__loading-container">
    <div class="__loading-content">
        <span class="loader"></span>
        <img src="../assets/images/spot-head.png" />
        <h1>{{ props.title }}</h1>
    </div>    
  </div>
</template>

<script>
import LoadingAsset from '../assets/images/spot-head.png';
export default {
  name: 'LoadingScreen',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Loading...'
    }
  },
  components: {
  },
  setup(props) {
    return {
        props,
        LoadingAsset
    }
  }
}
</script>

<style>
.__loading-container {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: #fff;
}

.__loading-container img {
  width: 100px;
  display: block;
  margin: 0 auto;
}

.__loading-container .__loading-content {
  position: absolute;
  height: auto;
  left: 50%;
  top: 47%;
  transform: translate(-50%, -50%);
}

.__loading-container h1 {
  font-style: normal;
  font-weight: 600;
  color: #144AB6;
  font-size: 30px;
  margin-top: 50px;
  text-align: center;
}

.loader {
  width: 190px;
  height: 190px;
  border: 8px solid #FFF;
  border-bottom-color: #144AB6;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  left: -16%;
  top: -16%;
}

@media only screen and (max-width: 680px) {
  .__loading-container h1 {
    font-size: 24px;
  }
  .__loading-container img {
    margin-top: 7px;
    width: 45px;
  }
  .loader {
    left: 0;
    width: 110px;
    height: 110px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
</style>

<template>
  <HomeView />
</template>

<script>
import HomeView from './views/HomeView.vue'

export default {
  name: 'App',
  components: {
    HomeView
  }
}
</script>

<style>

@import '../node_modules/ress/dist/ress.min.css';
@import '@/assets/base.css';


</style>
